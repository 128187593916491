// APPLICATION
export const WINDOW_SIZE_SET = "WINDOW_SIZE_SET";
export const BUST_CACHE = "BUST_CACHE";
export const CACHE_BUSTED = "CACHE_BUSTED";
export const WINDOW_BLUR_EVENT = "WINDOW_BLUR_EVENT";
export const WINDOW_FOCUS_EVENT = "WINDOW_FOCUS_EVENT";


// USER PROFILE
export const CURRENT_USER_LOADED = "CURRENT_USER_LOADED";
export const CURRENT_USER_UPDATED = "CURRENT_USER_UPDATED";
export const USER_PROFILE_SAVED_ERROR = "USER_PROFILE_SAVED_ERROR";
export const START_SAVE_USER_PROFILE = "START_SAVE_USER_PROFILE";
export const START_SAVE_USER_EMAIL = "START_SAVE_USER_EMAIL";
export const CURRENT_ORGANIZATION_LOADED = "CURRENT_ORGANIZATION_LOADED";
export const USER_ORGANIZATION_UPDATED = "USER_ORGANIZATION_UPDATED";

// SIGN UP
export const CLEAR_SIGNUP_STATUS = "CLEAR_SIGNUP_STATUS";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILED = "SIGNUP_FAILED";
export const SIGNUP_DUPLICATED = "SIGNUP_DUPLICATED";
export const SUBSCRIPTION_EDIT_REQUEST_SUCCESS = "SUBSCRIPTION_EDIT_REQUEST_SUCCESS";



// NOTIFICATIONS
export const NOTIFICATION_SETTINGS_CREATED = "NOTIFICATION_SETTINGS_CREATED";
export const NOTIFICATION_SETTINGS_LOADED = "NOTIFICATION_SETTINGS_LOADED";
export const NOTIFICATION_SETTINGS_UPDATED = "NOTIFICATION_SETTINGS_UPDATED";


// ELECTIONS
export const ELECTION_CREATED = "ELECTION_CREATED";
export const ELECTION_UPDATED = "ELECTION_UPDATED";
export const ELECTION_DELETED = "ELECTION_DELETED";
export const ELECTIONS_LOADED = "ELECTIONS_LOADED";
export const ELECTION_DETAILS_LOADED = "ELECTION_DETAILS_LOADED";
export const ELECTION_DATE_LOADED = "ELECTION_DATE_LOADED";
export const UPCOMING_ELECTIONS_LOADED = "UPCOMING_ELECTIONS_LOADED";
export const PAST_ELECTIONS_LOADED = "PAST_ELECTIONS_LOADED";
export const POLITICIAN_LOADED = "POLITICIAN_LOADED";
export const POLITICIANS_LOADED = "POLITICIANS_LOADED";
export const RATINGS_LOADED = "RATINGS_LOADED";


// SIGNUP
export const NEWSLETTER_SIGNUP_SUCCESS = "NEWSLETTER_SIGNUP_SUCCESS";
export const NEWSLETTER_SIGNUP_ERROR = "NEWSLETTER_SIGNUP_ERROR";


// ARTICLES

export const ARTICLE_CREATED = "ARTICLE_CREATED";
export const ARTICLE_UPDATED = "ARTICLE_UPDATED";
export const ARTICLE_PUBLISHED = "ARTICLE_PUBLISHED";
export const ARTICLE_DELETED = "ARTICLE_DELETED";
export const ARTICLE_DETAILS_LOADED = "ARTICLE_DETAILS_LOADED";
export const ARTICLE_LIKED = "ARTICLE_LIKED";
export const ARTICLE_LIKES_LOADED = "ARTICLE_LIKES_LOADED";
export const START_ARTICLE_FEED_LOAD = "START_ARTICLE_FEED_LOAD";
export const ARTICLE_FEED_LOADED = "ARTICLE_FEED_LOADED";
export const RECENT_ARTICLES_LOADED = "RECENT_ARTICLES_LOADED";
export const CLEAR_FEATURED_ARTICLES = "CLEAR_FEATURED_ARTICLES";
export const FEATURED_ARTICLES_LOADED = "FEATURED_ARTICLES_LOADED";


// EDITING
export const CREATING_ARTICLE = "CREATING_ARTICLE";
export const EDITING_ARTICLE = "EDITING_ARTICLE";
export const CLEAR_EDITOR_STATE = "CLEAR_EDITOR_STATE";


// COMMENTS / REPLIES
export const COMMENT_POSTED = "COMMENT_POSTED";
export const COMMENT_UPDATED = "COMMENT_UPDATED";
export const COMMENT_DELETED = "COMMENT_DELETED";
export const COMMENTS_LOADED = "COMMENTS_LOADED";
export const COMMENTS_RELOADED = "COMMENTS_RELOADED";
export const COMMENT_LIKED = "COMMENT_LIKED";
export const COMMENT_LIKES_LOADED = "COMMENT_LIKES_LOADED";
export const REPLY_POSTED = "REPLY_POSTED";
export const REPLY_UPDATED = "REPLY_UPDATED";
export const REPLY_DELETED = "REPLY_DELETED";
export const REPLIES_LOADED = "REPLIES_LOADED";
export const REPLIES_RELOADED = "REPLIES_RELOADED";
export const REPLY_LIKED = "REPLY_LIKED";
export const REPLY_LIKES_LOADED = "REPLY_LIKES_LOADED";
export const COMMENT_AND_REPLIES_LOADED = "COMMENT_AND_REPLIES_LOADED";
export const COMMENT_AND_REPLIES_ERROR = "COMMENT_AND_REPLIES_ERROR";


// GEOLOCATIONS & MAPS
export const GEOLOCATIONS_LOADED = "GEOLOCATIONS_LOADED";
export const LOCATION_DETAILS_LOADED = "LOCATION_DETAILS_LOADED";
export const LOCATION_CREATED = "LOCATION_CREATED";
export const USER_ADDRESS_LOCATION_CREATED = "USER_ADDRESS_LOCATION_CREATED";
export const GEOLOCATIONS_UPDATED = "GEOLOCATIONS_UPDATED";
export const MAPS_LIST_LOADED = "MAPS_LIST_LOADED";
export const MAP_DETAILS_LOADED = "MAP_DETAILS_LOADED";
export const MAP_LAYER_SET = "MAP_LAYER_SET";
export const VOTING_LOCATION_CREATED = "VOTING_LOCATION_CREATED";
export const VOTING_LOCATIONS_LOADED = "VOTING_LOCATIONS_LOADED";


export const CLEAR_SUCCESS = "CLEAR_SUCCESS";
export const RESET_TEXT = "RESET_TEXT";


// SITE NOTIFICATIONS
export const SITE_NOTIFICATION_CREATED = "SITE_NOTIFICATION_CREATED";
export const SITE_NOTIFICATION_UPDATED = "SITE_NOTIFICATION_UPDATED";
export const SITE_NOTIFICATIONS_LOADED = "SITE_NOTIFICATIONS_LOADED";
export const SITE_NOTIFICATION_DETAILS_LOADED = "SITE_NOTIFICATION_DETAILS_LOADED";
export const START_SITE_NOTIFICATIONS_LOAD = "START_SITE_NOTIFICATIONS_LOAD";
export const SITE_NOTIFICATIONS_LOAD_ERROR = "SITE_NOTIFICATIONS_LOAD_ERROR";
export const SITE_NOTIFICATION_PUBLISHED = "SITE_NOTIFICATION_PUBLISHED";
export const SITE_NOTIFICATION_CREATED_ERROR = "SITE_NOTIFICATION_CREATED_ERROR";
export const SITE_NOTIFICATION_DELETED = "SITE_NOTIFICATION_DELETED";
export const SITE_NOTIFICATIONS_CREATED_ERROR = "SITE_NOTIFICATIONS_CREATED_ERROR";

export const ACTIVE_SITE_NOTIFICATIONS_LOADED = "ACTIVE_SITE_NOTIFICATIONS_LOADED";
export const ACTIVE_SITE_NOTIFICATION_DISMISSED = "ACTIVE_SITE_NOTIFICATION_DISMISSED";


// USER LOCATIONS (MY LOCATIONS)
export const MAP_LOCATIONS_MATCHED = "MAP_LOCATIONS_MATCHED";
export const MAP_LOCATION_SET = "MAP_LOCATION_SET";
export const MAP_LOCATION_CLEAR = "MAP_LOCATION_CLEAR";

// REPORTS

export const SQL_REPORTS_LOADED = 'SQL_REPORTS_LOADED';
export const SQL_REPORT_EXECUTED = 'SQL_REPORT_EXECUTED';
export const SQL_REPORT_ERROR = 'SQL_REPORT_ERROR';