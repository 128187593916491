import React, {useEffect, useMemo, useState} from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./BottomContent.scss";
import Icon from "@src/components/shared/elements/Icon";
import TextPromo from "@src/components/TextPromo";
import NewsSection from "@src/components/NewsSection";
import MapSection from "@src/components/MapSection";
import Button from "@src/components/shared/elements/Button";

function BottomContent(props) {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate("/subscribe");
    }

    return (
        <div className={styles.root}>
            {props.newsOnlyMode ? (
                <section className={styles.newsFeed}>
                    <NewsSection header={props.header}/>
                </section>
            ) : (
                <>
                    <section className={styles.newsFeed}>
                        <NewsSection />
                    </section>
                    <section className={styles.mapList}>
                        <MapSection />
                    </section>
                </>
            )}
        </div>
    );
}

BottomContent.propTypes = {
    newsOnlyMode: PropTypes.bool,
    header: PropTypes.string,
};

BottomContent.defaultProps = {
    newsOnlyMode: false,
};

export default BottomContent;