import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "@src/pages/HomePage/HomePage.scss";
import Hero from "@src/components/Hero";
import {setPageTitle} from "@src/utils/windowHelpers";

function PrivacyPage({
    //...props
}) {
    const [policyText, setPolicyText] = useState("");


    useEffect(() => {
        setPageTitle("Privacy Policy", window.location);
    }, []);

    useEffect(() => {
        fetch("/api/v1/privacy-policy/")
            .then((response) => response.json())
            .then((data) => setPolicyText(data.policy_text))
            .catch((error) => console.error("Error fetching policy:", error));
    }, []);

    return (
        <div className={classnames(styles.root, styles.contactPage)}>
            <Hero
                imageFilename={"wcc_hero_background_strip.jpg"}
                imageAltText={"American flag and Tennessee flying"}
                headerText={`Privacy`}
                subpage
            />
            <main className={styles.main}>
                <section>
                    <h2>Privacy Policy</h2>
                    <div
                        dangerouslySetInnerHTML={ {__html: policyText} }
                    />
                </section>
            </main>
        </div>
    );
}


PrivacyPage.propTypes = {

}

export default PrivacyPage;
