import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import map from "lodash/map";
import * as ElectionActions from "@src/actions/ElectionActions";
import styles from "./IconLegend.scss";
import Icon from "@src/components/shared/elements/Icon";
import Button from "@src/components/shared/elements/Button";
import {cleanPublicUrl} from "@src/utils/publicBucketUtils";

const OTHER_KEYS = {
    "calendar-xmark": "End of current Term",
    "memo-pad": "Notes about this seat",
    "check-to-slot": "Upcoming Elections"
}

function IconLegend({ loadRatings, ratings, ratingsOnlyMode  }) {
    const navigate = useNavigate();

    useEffect(() => {
        loadRatings();
    }, []);

    const otherKeys = useMemo(() => {
        return map(OTHER_KEYS, (oDescription, oKey) => (
            <div className={styles.legendKey} key={oKey}>
                <div className={styles.iconKey}>
                    <Icon iconName={oKey} solid />
                </div>
                <div className={styles.label}>{oDescription}</div>
            </div>
        ))
    },[]);

    const renderImageTag = (image) => {
        return (
            <div className={styles.imageWrapper}>
                <div className={styles.image}>
                    <img src={cleanPublicUrl(image)} />
                </div>
            </div>
        );
    }

    const handleLearnMore = () => {
        console.log("IconLegend :: handleLearnMore :: LEARN MORE");
        navigate("/about/#ourValues");

    }

    const ratingsKey = useMemo(() => {
        return map(ratings, rating => {
            const iconKey = rating.image ? renderImageTag(rating.image) : <Icon iconName={rating.iconName || "circle"} solid />;
            return (
                <div className={classnames(styles.legendKey, styles.ratingsKey)} key={rating.id}>
                    <div className={styles.left}>
                        <div className={styles.iconKey}>{iconKey}</div>
                        <div className={styles.iconDescription}>
                            <div className={styles.name}>{rating.name}</div>
                            <div className={styles.label}>{rating.message}</div>
                        </div>
                    </div>
                </div>
            );
        })
    }, [ratings]);



    return (
        <div className={styles.root}>
            {ratingsOnlyMode ? (
                <div className={styles.keys}>
                    {ratingsKey}
                </div>
            ) : (
                <>
                    <div className={styles.title}>Legend</div>
                    <div className={styles.keys}>
                        {ratingsKey}
                        {/*{otherKeys}*/}
                    </div>
                </>
            )}
        </div>
    );
}


IconLegend.propTypes = {
    ratingsOnlyMode: PropTypes.bool,
}

IconLegend.defaultProps = {}

export default connect(
    (state, ownProps) => ({
        ratings: state.entities.ratings,
    }),
    (dispatch) => ({
        loadRatings: () => dispatch(ElectionActions.loadRatings()),
    })
)(IconLegend);
