import React, {useEffect, useMemo, useState} from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./BottomContent.scss";
import Icon from "@src/components/shared/elements/Icon";
import TextPromo from "@src/components/TextPromo";
import NewsSection from "@src/components/NewsSection";
import MapSection from "@src/components/MapSection";
import Button from "@src/components/shared/elements/Button";

function BottomContent(props) {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate("/subscribe");
    }

    return (
        <div className={styles.root}>

            <section className={styles.newsFeed}>
                <NewsSection />
            </section>
            <section className={styles.mapList}>
                <MapSection />
            </section>
            <main className={styles.main}>
                <section>
                    <h2>Getting Involved is Easy</h2>
                    <p className="center">You simply subscribe to our notifications, get informed, and go vote!</p>
                </section>

                <section>
                    <h2>With a free subscription, you can</h2>
                    <div className={styles.bulletGroup}>
                        <Icon circle colorHex={"#CC0000"} />
                        <p>Get notified about every upcoming election for your specific address. You will know when to
                            vote, where to vote, and which candidates share your grassroots conservative values.</p>
                    </div>
                    <div className={styles.bulletGroup}>
                        <Icon circle colorHex={"#CC0000"} />
                        <p>Find out which of your current elected officials are representing your values.</p>
                    </div>
                    <div className={styles.bulletGroup}>
                        <Icon circle colorHex={"#CC0000"} />
                        <p>Receive positive and informative content featuring your candidates and representatives.</p>
                    </div>
                    <div className={styles.subscription}>
                        <Button
                            onClick={handleButtonClick}
                            buttonText={"Get your free subscription!"}
                            buttonColor={"blue"}
                            large
                            wide
                        />
                    </div>
                </section>
            </main>

            {/*<section className={styles.joinToday}>*/}
            {/*    <Button*/}
            {/*        onClick={handleButtonClick}*/}
            {/*        buttonText={"Get your free subscription"}*/}
            {/*        buttonColor={"light"}*/}
            {/*        large*/}
            {/*        wide*/}
            {/*    />*/}
            {/*</section>*/}
        </div>
    );
}


BottomContent.propTypes = {

}

BottomContent.defaultProps = {

}

export default BottomContent;
