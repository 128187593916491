// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MapList__root{flex:none;width:100%;display:flex;flex-direction:row;flex-wrap:wrap;justify-content:center}
`, "",{"version":3,"sources":["webpack://./web/src/components/maps/MapList/MapList.scss"],"names":[],"mappings":"AAGA,eACE,SAAU,CACV,UAAW,CACX,YAAa,CACb,kBAAmB,CACnB,cAAe,CACf,sBAAuB","sourcesContent":["@import \"@commonstyles/vars.scss\";\n@import \"@commonstyles/colors.scss\";\n\n.root {\n  flex: none;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `MapList__root`
};
export default ___CSS_LOADER_EXPORT___;
