// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Image__root{width:100%;position:relative}.Image__root.Image__grayscale{filter:grayscale(100%)}.Image__root img{width:100%;-webkit-user-select:none;-moz-user-select:none;user-select:none;display:block}.Image__root .Image__overlay{top:0;left:0;height:100%;width:100%;position:absolute}
`, "",{"version":3,"sources":["webpack://./web/src/components/shared/elements/Image/Image.scss"],"names":[],"mappings":"AAGA,aACE,UAAW,CACX,iBAAkB,CAFpB,8BAKI,sBAAuB,CAL3B,iBASI,UAAW,CACX,wBAAiB,CAAjB,qBAAiB,CAAjB,gBAAiB,CACjB,aAAc,CAXlB,6BAcI,KAAM,CACN,MAAO,CACP,WAAY,CACZ,UAAW,CACX,iBAAkB","sourcesContent":["@import \"@commonstyles/vars.scss\";\n@import \"@commonstyles/colors.scss\";\n\n.root {\n  width: 100%;\n  position: relative;\n\n  &.grayscale {\n    filter: grayscale(100%);\n  }\n\n  img {\n    width: 100%;\n    user-select: none;\n    display: block;\n  }\n  .overlay {\n    top: 0;\n    left: 0;\n    height: 100%;\n    width: 100%;\n    position: absolute;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Image__root`,
	"grayscale": `Image__grayscale`,
	"overlay": `Image__overlay`
};
export default ___CSS_LOADER_EXPORT___;
