import React, {useEffect, useMemo, useState} from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./HomePage.scss";
import Hero from "@src/components/Hero";
import BottomContent from "@src/components/BottomContent";
import {setPageTitle} from "@src/utils/windowHelpers";
import Icon from "@src/components/shared/elements/Icon";
import Button from "@src/components/shared/elements/Button";
import AddressLookup from "@src/components/maps/AddressLookup";
import * as MapActions from "@src/actions/MapActions";

function HomePage({
    setMapLocation,
}) {
    const navigate = useNavigate();

    useEffect(() => {
        setPageTitle("Home", window.location);
    }, []);

    const handleButtonClick = () => {
        navigate("/subscribe");
    }

    const handlePlaceSelected = (placesInfo) => {
        console.log("HomePage :: handlePlaceSelected :: placesInfo = ", placesInfo);
        setMapLocation(placesInfo);
        setTimeout(() => {
            navigate("/local-government/tn-williamson-address-lookup/");
        }, 100);
    }

    return (
        <div className={ styles.root }>
            <Hero
                imageFilename={ "wcc_hero_background_strip.jpg" }
                imageAltText={ "American flag and Tennessee flying" }
                headerText={ "Your Conservative Grassroots Network" }
                subtitleText={ <span>in Williamson County, Tennessee</span> }
                buttonText={ "Join Now" }
                footerText={ "Free. 100% Privacy. Cancel Anytime." }
                onButtonClick={ handleButtonClick }
            />
            <main className={ styles.main }>
                {/*<h1>Williamson County Citizens</h1>*/ }
                <section>
                    <h3>Who We Are</h3>
                    <div className={ styles.divider }/>
                    <p>We are a volunteer network of local voters that elect grassroots conservatives to public
                        office.<br/>We
                        stand for <span className={ styles.real }>Real</span> representative government in
                        Williamson
                        County.</p>
                </section>
                <section>
                    <h2>Getting Involved is Easy</h2>
                    <div className={ styles.divider }/>
                    <p>Join the network, get informed, and vote in all elections!</p>

                    <h2>By Joining Our Network You Can</h2>
                    <div className={ styles.divider }/>
                    <br/>
                    <div className={ styles.bulletGroup }>
                        <Icon circle colorHex={ "#CC0000" }/>
                        <p>Get notified about every upcoming local election. You will know when to
                            vote, where to vote, and which candidates share your grassroots conservative values.</p>
                    </div>
                    <div className={ styles.bulletGroup }>
                        <Icon circle colorHex={ "#CC0000" }/>
                        <p>Find out which of your current elected officials are representing your values.</p>
                    </div>
                    <div className={ styles.bulletGroup }>
                        <Icon circle colorHex={ "#CC0000" }/>
                        <p>Receive positive and informative content featuring your candidates and representatives.</p>
                    </div>
                    <div className={ styles.subscription }>
                        <Button
                            onClick={ handleButtonClick }
                            buttonText={ "Join Now!" }
                            buttonColor={ "blue" }
                            large
                            wide
                        />
                    </div>
                </section>
            </main>
            <div className={ styles.mainDivider }/>
            <section className={ styles.addressLookUpSection }>
                <div className={styles.addressLookUp}>
                    <h1>ADDRESS LOOK UP TOOL</h1>
                    <p>Enter your home address to view your list of government representatives.</p>
                    <div className={ styles.lookup }>
                        <AddressLookup isSignUp onPlaceSelected={ handlePlaceSelected }/>
                    </div>
                </div>
            </section>
            <BottomContent/>
            <main className={classnames(styles.main, styles.bottomSection)}>

                <div className={ styles.mainDivider }/>
                <section>
                    <h2>We are Williamson County Citizens</h2>
                    <div className={ styles.divider }/>
                    <p>Whether you’ve lived here for generations or have relocated in recent years, Williamson County is
                        a special place to call home. Most likely you have chosen to live here for conservative ideals
                        such as low crime, great schools, Judeo-Christian values, individual liberties, and quality of
                        life.</p>
                </section>
                <section>
                    <h3>The Challenge</h3>
                    <div className={ styles.divider }/>
                    <p>Unfortunately, our local political governance has us headed away from these conservative ideals
                        and down the path of becoming the next Davidson County. Our local government resembles a
                        political aristocracy more than it does real representation. Many of our elected officials say
                        all the right things but do not promote authentic conservative policy.</p>
                </section>
                <section>
                    <h3>Our Solution</h3>
                    <div className={ styles.divider }/>
                    <p>Thankfully, as conservative citizens in Williamson County we are in the majority. The simple
                        solution is voter turnout. If we as conservatives vote in <strong>ALL</strong> available
                        elections, <u>we win</u>!</p>
                    <p>Your vote in city, county, and state elections is the most powerful force you have against the
                        advancement of establishment and liberal agendas in your community.</p>
                </section>
                <br/>
            </main>
        </div>
    );
}


HomePage.propTypes = {}

HomePage.defaultProps = {}

export default connect(null, (dispatch) => ({
    setMapLocation: (location) => dispatch(MapActions.setMapLocation(location)),
}))(HomePage);
