import React, { useEffect, useState } from 'react';
import { loadReports } from "@src/api/ReportApi";

const ReportsPage = () => {
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Load SQL reports on component mount
        loadReports()
            .then(response => {
                setReports(response.body);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error loading SQL reports:", error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div>
            <h2>Admin Reports</h2>
            <ul>
                {reports.map(report => (
                    <div>
                        {/* Link to the execute URL */}
                        <a href={`/api/v1/reports/${report.id}/execute`} target="_blank" rel="noopener noreferrer">
                            {report.name}
                        </a>
                    </div>

                ))}
            </ul>
        </div>
    );
};

export default ReportsPage;
