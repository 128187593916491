import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./ElectionsCalendar.scss";
import * as ElectionActions from "@src/actions/ElectionActions";
import ElectionCalendarItem from "@src/components/elections/ElectionCalendarItem";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import Hero from "@src/components/Hero";
import IconLegend from "@src/components/IconLegend";
import {setPageTitle} from "@src/utils/windowHelpers";

function ElectionsCalendar({ elections, loadElectionCalendar, loadPastElections, pastElections }) {
    useEffect(() => {
        window.scrollTo(0, 0);
        loadElectionCalendar();
        loadPastElections();
        setPageTitle("Elections Calendar", window.location);
    }, []);


    const renderCalendarItems = () => {
        const calendarItems = map(orderBy(elections, ["votingDate"], ["asc"]), electionInfo => (
            <ElectionCalendarItem config={electionInfo} key={electionInfo.id} />
        ));

        if (!calendarItems.length) {
            return (
                <div className={styles.noUpcoming}>
                    <h3>NO UPCOMING ELECTIONS</h3>
                </div>
            )
        }

        return calendarItems;
    }


    const renderPastElections = () => {
        const calendarItems = map(orderBy(pastElections, ["votingDate"], ["desc"]), electionInfo => (
            <ElectionCalendarItem config={electionInfo} key={electionInfo.id} isPast />
        ));

        if (!calendarItems.length) {
            return (
                <div className={styles.noUpcoming}>
                    <h3>NO PAST ELECTIONS AVAILABLE</h3>
                </div>
            )
        }

        return calendarItems;
    }

    return (
        <div className={styles.root}>
            <Hero
                imageFilename={"wcc_hero_background_strip.jpg"}
                imageAltText={"American flag and Tennessee flying"}
                headerText={"Election Calendar"}
                subpage
            />
            <div className={styles.inner}>
                <h1>Upcoming Local Elections</h1>
                <p className={styles.disclaimer}>(US Senate and Presidential elections are not considered to be local)</p>
                <div className={styles.calendarItems}>
                    <IconLegend ratingsOnlyMode/>
                    {renderCalendarItems()}
                </div>
                <h1>Past Elections</h1>
                <div className={styles.calendarItems}>
                    {renderPastElections()}
                </div>
            </div>
        </div>
    );
}


ElectionsCalendar.propTypes = {}

ElectionsCalendar.defaultProps = {}

export default connect(
    (state, ownProps) => ({
        elections: state.entities.upcomingElections,
        pastElections: state.entities.pastElections,
    }),
    (dispatch) => ({
        loadElectionCalendar: () => dispatch(ElectionActions.loadElectionCalendar()),
        loadPastElections: () => dispatch(ElectionActions.loadPastElections()),
    })
)(ElectionsCalendar);
