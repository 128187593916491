import React from "react";
import styles from "./MapSection.scss";
import MapListContent from "@src/components/maps/MapListContent";

function MapSection({}) {
    return (
        <div className={styles.root}>
            <h2>Find Your Local Representatives</h2>
            <div className={styles.mapContent}>
                <MapListContent isHomepage />
            </div>
        </div>
    );
}

MapSection.propTypes = {}

export default MapSection;
