import React, {useEffect, useMemo, useRef, useState} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./MapPage.scss";
import loMap from "lodash/map";
import flatMap from "lodash/flatMap";
import FeaturesList from "@src/components/maps/FeaturesList";
import Hero from "@src/components/Hero";
import IconLegend from "@src/components/IconLegend";
import InfoMessage from "@src/components/shared/widgets/InfoMessage";
import MapContainer from "@src/components/maps/MapContainer";
import Panel from "@src/components/shared/widgets/Panel";
import TextMarker from "@src/components/maps/Google/TextMarker";
import WithErrorBoundary from "@src/components/shared/elements/WithErrorBoundary";
import UserLocations from "@src/components/maps/UserLocations";
import * as ElectionActions from "@src/actions/ElectionActions";
import {loadMapDetailsBySlug} from "@src/actions/MapActions"
import {loadCurrentUser} from "@src/actions/UserActions";
import {setPageTitle} from "@src/utils/windowHelpers";

const DEFAULT_POPUP_DATA = {
    name: "",
    title: "",
    votingPrecinct: ""
}

const COMPONENT_NAME = "Map Page";

function MapPage({
    mapSlug,
    mapConfig,
    mapLayers,
    loadAllPoliticians,
    loadMapBySlug,
    showMapList,
    userLocations
}) {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        loadAllPoliticians();
    }, []);

    const handleMapLoaded = () => {
        setIsLoaded(true);
    }

    useEffect(() => {
        if (mapConfig) {
            setPageTitle(`${mapConfig.name} :: Map`, window.location);
        }
    }, [mapConfig]);

    useEffect(() => {
        if (!mapConfig?.mapLayers && mapSlug) {
            loadMapBySlug(mapSlug);
        }
    }, [mapConfig, mapSlug]);


    const mapFeaturesList = useMemo(() => {
        if (isLoaded && !mapConfig.allowSearch && mapLayers) {
            return (
                <WithErrorBoundary
                    componentName="FeaturesList"
                >
                    <FeaturesList mapSlug={mapSlug} />
                </WithErrorBoundary>
            );
        }
        return null;
    }, [isLoaded, mapConfig, mapLayers]);

    const layerLabels = useMemo(() => {
        if (mapConfig) {
            return flatMap(mapConfig.mapLayers, layer => {
                if (layer.showData) {
                    return loMap(layer.geoLocation.labels, mapLabel => (
                        <TextMarker
                            key={`layer_${layer.order}_label_${mapLabel.featureId}_marker`}
                            text={mapLabel.label}
                            lat={Number(mapLabel.latitude)}
                            lng={Number(mapLabel.longitude)}
                            light={layer.layerOptions.lightLabels}
                        />
                    ));
                }
                return [];
            });
        }
        return null;
    }, [mapConfig]);

    const renderedMap = useMemo(() => {
        if (mapConfig?.mapLayers) {
            return (
                <MapContainer
                    key={mapSlug}
                    mapConfig={mapConfig}
                    onMapLoaded={handleMapLoaded}
                >
                    {layerLabels}
                </MapContainer>
            );
        }
        return null;
    }, [mapConfig]);

    const loaderComponent = useMemo(() => {
        return isLoaded ? null : <div>LOADING MAP ... </div>
    }, [isLoaded]);

    const showLegend = (mapConfig && !mapConfig.allowSearch) || !!userLocations;
    return (
        <div className={styles.root}>
            <Hero
                imageFilename={"wcc_hero_background_strip.jpg"}
                imageAltText={"American flag and Tennessee flying"}
                headerText={"Your Local Government"}
                subpage
            />
            {/*<Link className={styles.backLink} to={"/maps/"}><i className={"fa-solid fa-chevron-left"} /> Election Maps</Link>*/}
            <div className={styles.inner}>
                {/*<MapLinkButtons selected={mapConfig?.slug} />*/}
                {mapConfig && (
                    <div className={styles.mapHeader}>
                        <Link to={"/local-government/"} className={styles.backLink}><i className="fa-solid fa-chevron-left" /> All Maps</Link>
                        <h2>{mapConfig.name}</h2>
                    </div>
                )}
                {loaderComponent}
                {renderedMap}
                {mapConfig?.infoMessage && (
                    <InfoMessage contentType={"info"} light title={mapConfig.infoMessageTitle} messageContent={<div className={styles.infoMessageInner} dangerouslySetInnerHTML={{__html: mapConfig.infoMessage }} />} />
                )}
                {mapConfig?.allowSearch && <UserLocations mapSlug={mapSlug} />}
                {mapFeaturesList}
                {showLegend && <IconLegend />}
            </div>
        </div>
    );
}


MapPage.propTypes = {
    mapSlug: PropTypes.string.isRequired,
    showMapList: PropTypes.bool,
}

MapPage.defaultProps = {}

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const userAddress = userProfile?.address;

        // Get the mapConfig based on slug
        const mapConfig = state.mapState.maps?.[ownProps.mapSlug];
        const mapLayers = state.mapState.mapLayers[ownProps.mapSlug];
        return {
            userProfile,
            userAddress,
            mapConfig,
            mapLayers,
            userLocations: state.user.locations,
        }
    },
    (dispatch) => ({
        loadCurrentUser: () => dispatch(loadCurrentUser()),
        loadMapBySlug: (slug) => dispatch(loadMapDetailsBySlug(slug)),
        loadAllPoliticians: () => dispatch(ElectionActions.loadPoliticians()),
    })
)(MapPage);
