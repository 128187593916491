import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./Image.scss";

import getStaticFile from "@src/utils/getStaticFile";


function Image({
    aspectRatio,
    cropped,
    grayscale,
    onResize,
    src,
    video
               }) {
    const [loading, setLoading] = useState(true);
    const [imgHeight, setImgHeight] = useState(1);
    const [imgWidth, setImgWidth] = useState(1);
    const onLoad = (e) => {
        setLoading(false);
        setImgWidth(e.target.naturalWidth);
        setImgHeight(e.target.naturalHeight);
        if (onResize) {
            onResize({
                width: e.target.naturalWidth,
                height: e.target.naturalHeight,
            });
        }
    };

    const imageSrc = useMemo(() => {
        return src || getStaticFile("img/wcc_article_placeholder.png");
    }, [src]);

    let rootStyle = {};

    let imgComponent = (
        <img
            src={imageSrc}
            onLoad={onLoad}
        />
    );

    if (aspectRatio) {
        rootStyle = {
            height: 0,
            paddingBottom: `${1 / aspectRatio * 100}%`,
            overflow: "hidden",
        };
        let imgStyle = {
            position: "absolute",
            width: "100%",
            top: "50%",
            transform: "translateY(-50%)",
        };
        const imageAspect = imgWidth / imgHeight;
        if ((!cropped && imageAspect < aspectRatio) || (cropped && imageAspect > aspectRatio)) {
            imgStyle = {
                position: "absolute",
                height: "100%",
                width: `${(imageAspect / aspectRatio) * 100}%`,
                transform: "translateX(-50%)",
                left: "50%",
            };
        }
        imgComponent = (
            <div style={imgStyle}>
                {imgComponent}
            </div>
        );
    }

    return (
        <div
            className={classnames(styles.root, {
                [styles.grayscale]: grayscale,
            })}
            style={rootStyle}
        >
            {imgComponent}
            <div className={styles.overlay} />
        </div>
    );
}

Image.propTypes = {
    src: PropTypes.string,
    cropped: PropTypes.bool,
    aspectRatio: PropTypes.number,
    onResize: PropTypes.func,
    grayscale: PropTypes.bool,
};


export default Image;
