import React, {useEffect, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./MapLink.scss";
import getStaticFile from "@src/utils/getStaticFile";
import {cleanPublicUrl} from "@src/utils/publicBucketUtils";

function MapLink({ mapInfo, order }) {
    const imageNumber = order < 4 ? order : order % 4;
    const mapPlaceholderImage = getStaticFile(`img/map_image${imageNumber}.jpg`);
    const mapImage = cleanPublicUrl(mapInfo.thumbnail?.file) || mapPlaceholderImage;

    if (mapInfo.empty) {
        return <div className={classnames(styles.root, styles.emptySpace)} />
    }

    return (
        <div className={classnames(styles.root)}>
            <Link className={styles.link} to={`/local-government/${mapInfo.slug}/`}>
                <div className={styles.cardImage}>
                    <img src={mapImage} alt="Map illustration" />
                    {mapInfo.isDraft && <div className={styles.draft}><span>Draft</span></div>}
                </div>
                <h3>{mapInfo.name}</h3>
            </Link>
        </div>
    );
}


MapLink.propTypes = {
    mapInfo: PropTypes.object.isRequired,
    order: PropTypes.number,
}

export default MapLink;
