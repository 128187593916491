import React, {useEffect, useMemo} from "react";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./MapList.scss";
import map from "lodash/map";
import filter from "lodash/filter";
import MapLink from "./MapLink";
import * as MapActions from "@src/actions/MapActions";

function MapList({ maps, loadMaps, onHasDefault, level }) {

    useEffect(() => {
        loadMaps();
    }, []);

    useEffect(() => {
        const defaultMapList = filter(maps, ["isDefault", true]);
        let defaultMap;
        if (defaultMapList.length) {
            defaultMap = defaultMapList[0].slug
        }

        const mapsKeys = Object.keys(maps||{});
        if (mapsKeys.length === 1) {
            defaultMap = maps[mapsKeys[0]].slug;
        }

        if (defaultMap) {
            onHasDefault(defaultMap);
        }
    }, [maps]);

    const mapLinks = useMemo(() => {
        if (maps) {
            const filteredMaps = filter(maps, mapItem => (mapItem.governmentLevel === level && !mapItem.isLookup));
            const extraSpace = filteredMaps.length % 2 === 1 ? [{ empty: true }] : [];
            return map([...filteredMaps, ...extraSpace], mapData => {
                return(
                    <MapLink mapInfo={mapData} key={mapData.id} />
                )
            })
        }

        return [];
    }, [maps]);

    if (!mapLinks.length) {
        return (
            <div className={styles.root}>
                <h3>No Maps to View</h3>
            </div>
        );
    }

    return (
        <div className={styles.root}>
            {mapLinks}
        </div>
    );
}


MapList.propTypes = {
    onHasDefault: PropTypes.func.isRequired,
    level: PropTypes.string,
}

export default connect(
    (state, ownProps) => {
        return {
            maps: state.mapState.maps,
        }
    },
    (dispatch) => ({
        loadMaps: () => dispatch(MapActions.loadMaps()),
    })
)(MapList);
