import React, {useEffect, useMemo, useRef, useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "../HomePage/HomePage.scss";
import {Link} from "react-router-dom";
import Button from "@src/components/shared/elements/Button";
import BottomContent from "@src/components/BottomContent";
import Hero from "@src/components/Hero";
import {setPageTitle} from "@src/utils/windowHelpers";
import getStaticFile from "@src/utils/getStaticFile";

function AboutPage(props) {
    const ourValuesRef = useRef();
    const grassrootsImg = getStaticFile("img/grassroots_symbol.png");

    useEffect(() => {
        setPageTitle("About", window.location);
    }, []);

    useEffect(() => {
        const deepLink = window.location.hash;
        if (deepLink === "#ourValues" && ourValuesRef.current) {
            const linkPos = ourValuesRef.current.getBoundingClientRect();
            setTimeout(() => {
                window.scrollTo({ left: 0, top: linkPos.top, behavior: "smooth" });
            }, 250);
        }
    }, [ourValuesRef]);

    return (
        <div className={styles.root}>
            <Hero
                imageFilename={"wcc_hero_background_strip.jpg"}
                imageAltText={"American flag and Tennessee flying"}
                headerText={"Williamson County Citizens"}
                subpage
            />
            <main className={styles.main}>
                <section>
                    <h1>About Us</h1>
                    <div className={styles.divider} />
                    <p>Are you satisfied with how the Republican Party represents your interests in Washington, D.C.?</p>
                    <p>Did you know that the same establishment agenda prevails right here in Williamson County?</p>
                    <p>We are here to help you change that.</p>
                </section>
                <section>
                    <h2>Who we are</h2>
                    <div className={styles.divider} />
                    <p>Williamson County Citizens is dedicated to building a government led by true Grassroots Conservatives – leaders who put the will of the people first and uphold the founding principles of the United States, free from the influence of special interests. We believe that many of our current representatives, particularly establishment Republicans, no longer represent the values or best interests of their constituents in Williamson County. The same entrenched political agenda that frustrates you in Washington is prevalent here.</p>
                    <p>We aim to work with the community to restore integrity, accountability, and conservative values to every level of public office by electing candidates who defend liberty and limit government’s reach. By empowering local communities and securing fraud-proof elections, we will create a government that serves the people with transparency and conviction.</p>
                </section>
                <section>
                    <h2>What we do</h2>
                    <div className={styles.divider} />
                    <p>Williamson County Citizens provides the knowledge, tools, and support needed for Grassroots Conservatives to take back control of our local and national governments.</p>
                </section>
                <section>
                    <h3>We do the Legwork</h3>
                    <div className={styles.divider} />
                    <p>We know that most conservatives don’t have the time to attend local meetings, research candidates, or follow every election closely. We do the work to ensure you know who truly represents your values, making it easier for you to make informed decisions at the polls.</p>
                </section>
                <section>
                    <h3>We keep you informed</h3>
                    <div className={styles.divider} />
                    <p>We ensure you know when elections are happening, where to vote, and which candidates are committed to conservative principles. Local elections are often decided by narrow margins, and informed voting can make all the difference between real representation and the same old politics.</p>
                </section>
                <section>
                    <h3>We build a strong bench</h3>
                    <div className={styles.divider} />
                    <p>We focus on winning local elections today to create a strong “bench” of future conservative leaders. Tomorrow’s senators, governors, and representatives often start in local offices, and by supporting grassroots candidates at every level, we are laying the foundation for long-term, meaningful change.</p>
                </section>
                <section>
                    <a name="ourValues" ref={ourValuesRef} />
                    <h2>What is a Grassroots Conservative?</h2>
                    <div className={styles.divider} />
                    <p>As you review our database of elected officials, you will occasionally see this icon next to some of their names:</p>
                    <div className={styles.iconExplain}>
                        <div className={styles.iconImg}>
                        <img src={grassrootsImg} />
                    </div>
                    <p>This icon means that the candidate has been vetted and found to have demonstrated a track record of supporting Grassroots Conservative values. Here are some examples of values that qualify a candidate to receive this endorsement:</p>
                    </div>
                </section>
                <section>
                    <h3>Do's</h3>
                    <ul className={styles.redBullets}>
                        <li><strong>Prioritize the People over Power.</strong> Grassroots Conservatives are loyal to their constituents and the principles of the American founding – not to their own institutional power and access to taxpayer funds and no-bid contracts.</li>
                        <li><strong>Support fraud-proof elections.</strong> Grassroots Conservatives advocate for secure voting methods like paper ballots instead of machines, precinct-based voting instead of consolidated voting centers, and citizen-only voting. Additionally, closed primaries ensure that only registered party members decide their nominees.</li>
                        <li><strong>Limit Government's Reach.</strong> By reducing government spending, cutting regulations, lowering taxes, and eliminating debt, Grassroots Conservatives fight to limit the size and reach of government.</li>
                        <li><strong>Win with Ideas and Truth.</strong> Grassroots Conservatives believe in winning elections by offering better solutions – not by silencing or demeaning political opponents.</li>
                        <li><strong>Support Fellow Grassroots Candidates.</strong> Seeing themselves as part of a broader movement, Grassroots Conservatives actively promote and support like-minded people running for office.</li>
                    </ul>
                </section>
                <section>
                    <h3>Don'ts</h3>
                    <ul className={styles.redBullets}>
                        <li><strong>No Special Interests.</strong> Grassroots Conservatives are not funded by powerful lobbyists or outside influences. Unlike establishment Republicans, these candidates are free from the sway of millions of dollars pouring in from D.C. and foreign sources.</li>
                        <li><strong>Reject Woke Ideologies.</strong> Grassroots Conservatives oppose divisive ideologies like DEI (Diversity, Equity, and Inclusion), Pride, and the broader "woke" agenda.</li>
                    </ul>

                    <p>Together, we will restore power to the people, elect true conservatives, and build a future where the government serves the citizens, not the political class. It’s time to unite, stay focused, and <strong>win!</strong></p>
                </section>
                <section>
                    <h3>We Need Your Help!</h3>
                    <div className={styles.divider} />
                    <p>Whether you're new to the area or your family has been here for generations, this is your home and your children's future.</p>
                    <p>We need every single vote to make a difference. Grassroots movements grow one person at a time – neighbor to neighbor, citizen to citizen.</p>
                    <p>Grassroots Conservatives are much more numerous than establishment Republicans, which is why they fear us. If we organize the Grassroots Conservative vote, we can’t lose. Take action today by <Link to={"/subscribe"}>subscribing</Link>, and then grow our reach by sharing this website with everyone you know.</p>
                </section>

                <BottomContent />
            </main>
        </div>
    );
}


AboutPage.propTypes = {}

AboutPage.defaultProps = {}

export default AboutPage;
