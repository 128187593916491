import request from "@src/utils/request";

export function loadReports() {
    return request()
        .get(`/api/v1/reports/`)
        .send();
}

export function executeSqlReport(reportId) {
    return request()
        .get(`/api/reports/${reportId}/execute/`)
        .send();
}