import React, {useEffect, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import filter from "lodash/filter";
import map from "lodash/map";
import styles from "./ArticleTile.scss";
import moment from "moment";
import getStaticFile from "@src/utils/getStaticFile";
import {cleanPublicUrl} from "@src/utils/publicBucketUtils";
import Image from "@src/components/shared/elements/Image";
import {charLimit} from "@src/utils/text_helpers";

function ArticleTile({ article }) {
    const publishedDate = moment(article.publishedDate).format("MMMM DD, YYYY");
    const authorName = "Williamson County Citizens"; // article.author?.fullName || "Staff";

    return (
        <Link className={classnames(styles.root, "topRedBorder")} to={`/grassroots-spotlight/${article.path}/`}>
            <Image src={article.teaserImage?.thumbnail} />
            <div className={styles.inner}>
                <div className={styles.date}>{publishedDate}</div>
                <h3 className={styles.headline}>{article.headline}</h3>
                {/*<div className={styles.author}>By {authorName}</div>*/}
                <div className={styles.bodyText}>{charLimit(article.summary, 150)}</div>
            </div>
        </Link>
    );
}


ArticleTile.propTypes = {
    article: PropTypes.object.isRequired,
}

export default ArticleTile;
