// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Carousel__root{display:flex;flex-direction:row;justify-content:center;width:100%}.Carousel__root .Carousel__next,.Carousel__root .Carousel__prev{flex:none;max-width:30px;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:2rem}.Carousel__root .Carousel__listContainer{flex:1;max-width:320px;overflow:hidden;display:flex;flex-direction:row}.Carousel__root .Carousel__listContainer .Carousel__list{flex:none;display:flex;flex-direction:row;justify-content:flex-start}.Carousel__root .Carousel__controls{z-index:9999}.Carousel__root .Button__root.icon-btn{padding:0.5em 0.25em}@media (min-width: 430px){.Carousel__root{width:auto}.Carousel__root .Carousel__listContainer{flex:none;width:320px;max-width:none}}@media (min-width: 768px){.Carousel__root .Carousel__listContainer{width:680px}}@media (min-width: 1024px){.Carousel__root .Carousel__listContainer{width:100%}.Carousel__root .Carousel__next,.Carousel__root .Carousel__prev{display:none}}
`, "",{"version":3,"sources":["webpack://./web/src/components/shared/widgets/Carousel/Carousel.scss"],"names":[],"mappings":"AAGA,gBACE,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CACvB,UAAW,CAJb,gEAOI,SAAU,CACV,cAAe,CACf,YAAa,CACb,qBAAsB,CACtB,kBAAmB,CACnB,sBAAuB,CACvB,cAAe,CAbnB,yCAiBI,MAAO,CACP,eAAgB,CAChB,eAAgB,CAChB,YAAa,CACb,kBAAmB,CArBvB,yDAwBM,SAAU,CACV,YAAa,CACb,kBAAmB,CACnB,0BAA2B,CA3BjC,oCAgCI,YAAa,CAhCjB,uCAoCI,oBAAqB,CACtB,0BArCH,gBAwCI,UAAW,CAxCf,yCA0CM,SAAU,CACV,WAAY,CACZ,cAAe,CAChB,CAGH,0BAhDF,yCAkDM,WAAY,CACb,CAGH,2BAtDF,yCAwDM,UAAW,CAxDjB,gEA4DM,YAAa,CACd","sourcesContent":["@import \"@commonstyles/vars.scss\";\n@import \"@commonstyles/colors.scss\";\n\n.root {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  width: 100%;\n\n  .next, .prev {\n    flex: none;\n    max-width: 30px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    font-size: 2rem;\n  }\n\n  .listContainer {\n    flex: 1;\n    max-width: 320px;\n    overflow: hidden;\n    display: flex;\n    flex-direction: row;\n\n    .list {\n      flex: none;\n      display: flex;\n      flex-direction: row;\n      justify-content: flex-start;\n    }\n  }\n\n  .controls {\n    z-index: 9999;\n  }\n\n  :global(.Button__root.icon-btn) {\n    padding: 0.5em 0.25em;\n  }\n\n  @media (min-width: $breakpointXsm) {\n    width: auto;\n    .listContainer {\n      flex: none;\n      width: 320px;\n      max-width: none;\n    }\n  }\n\n  @media (min-width: $breakpointSm) {\n    .listContainer {\n      width: 680px;\n    }\n  }\n\n  @media (min-width: $breakpointMd) {\n    .listContainer {\n      width: 100%;\n    }\n\n    .next, .prev {\n      display: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Carousel__root`,
	"next": `Carousel__next`,
	"prev": `Carousel__prev`,
	"listContainer": `Carousel__listContainer`,
	"list": `Carousel__list`,
	"controls": `Carousel__controls`
};
export default ___CSS_LOADER_EXPORT___;
