import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import map from "lodash/map";
import styles from "./ElectionCandidateCard.scss";
import Icon from "@src/components/shared/elements/Icon";
import ProgressBar from "@src/components/shared/elements/ProgressBar";
import { addCommasToNumber } from "@src/utils/numeric";
import RatingsTag from "@src/components/PoliticianInfo/RatingsTag";
import PoliticalParty from "@src/components/shared/widgets/PoliticalParty";

function ElectionCandidateCard({ candidate, wasUnopposed, showVs }) {
    const party = candidate?.party;
    const candidateRating = candidate?.rating;
    const articlePath = candidate?.articles[0]?.path
    const renderResult = ({ id, locationName, votes, votesPercent, winner, isTotal = false }) => (
        <div
            className={classnames(styles.result, {
                [styles.total]: isTotal,
            })}
            key={`result_${id}`}
        >
            <div className={styles.locationName}>
                <h5>{locationName}</h5>
                <div className={styles.voteCount}>{addCommasToNumber(votes)}</div>
            </div>

            <div className={styles.votes}>
                <div className={styles.voteBar}><ProgressBar percent={votesPercent} colors={winner ? ["#399337"] : ["#CC0000"]} thin /></div>
                <div className={styles.votePercent}>{votesPercent}%</div>
            </div>
        </div>
    );

    const candidateLink = useMemo(() => {
        if (candidate?.website) {
            return (
                <div className={styles.candidateLink}>
                    <a href={candidate.website} target="_blank" title={`${candidate.fullName} campaign website`}>Campaign Website</a>
                    {articlePath && (
                        <a
                            href={`/grassroots-spotlight/${articlePath}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Watch Video
                        </a>
                    )}

                </div>
            );
        }
        return null;
    }, [candidate]);

    const candidateResults = useMemo(() => {
        if (candidate?.votes) {
            const totalVotes = renderResult({
                votes: candidate.votes,
                votesPercent: candidate.votesPercent,
                locationName: "Votes",
                winner: candidate.winner,
                isTotal: true,
            });

            const breakDown = map(candidate.results, result => renderResult(result));
            const winLabel = candidate?.winner ? <i className={"fa-solid fa-circle-check"} /> : <i className={"fa-solid fa-circle-x"} />;
            return (
                <div className={classnames(styles.results, {
                    [styles.winner]: candidate.winner,
                })}>
                    <div className={styles.winLabel}>
                        {winLabel}
                    </div>
                    <div className={styles.breakdowns}>
                        {totalVotes}
                        {breakDown}
                    </div>
                </div>
            )
        }
        return null;
    }, [candidate]);

    const cardLabel = useMemo(() => {
        if (wasUnopposed) {
            return <div className={styles.message}>No Opposing Candidate</div>
        }

        return <h3>{candidate?.fullName || "No Recommendation"}</h3>;
    }, [candidate, wasUnopposed]);

    return (
        <>
            {showVs && <div className={styles.vs}><span>vs.</span></div>}
            <div className={classnames(styles.root, {
                [styles.noRecommendation]: !candidate,
                [styles.unopposed]: wasUnopposed,
            })}>
                <div className={styles.candidate}>
                    <div className={styles.mainContent}>
                        <div className={styles.nameParty}>
                            {cardLabel}
                            {candidateRating && <RatingsTag rating={candidateRating} small />}
                            {party && <PoliticalParty party={party} />}
                        </div>
                        {candidateLink}
                    </div>
                </div>
                {candidateResults}
            </div>
        </>
    );
}


ElectionCandidateCard.propTypes = {
    candidate: PropTypes.object,
    wasUnopposed: PropTypes.bool,
    showVs: PropTypes.bool,
}

export default ElectionCandidateCard;
