import React, {useMemo, useRef, useState} from "react";
import {connect} from "react-redux";
import { NavLink, Link } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./SiteHeader.scss";
import getStaticFile from "@src/utils/getStaticFile";
import Icon from "@src/components/shared/elements/Icon";
import LoginLink from "@src/components/shared/widgets/LoginLink";
import {loadCurrentUser} from "@src/actions/UserActions";
import Button from "@src/components/shared/elements/Button";
import HamburgerMenu from "@src/components/SiteHeader/HamburgerMenu";
import {set} from "lodash";
import SiteNotification from "@src/components/site/SiteNotification";

function SiteHeader({
    userProfile
}) {
    const [isLoggedIn, setIsLoggedIn] = useState(window.ENV.IS_LOGGED_IN);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [adminMenuIsOpen, setAdminMenuIsOpen] = useState(false);
    const logoImage = getStaticFile("img/wcc_text_logo_white.png");

    const isAdmin = useMemo(() => {
        if (isLoggedIn && userProfile) {
            return userProfile.isAdmin;
        }
        return false;
    }, [isLoggedIn, userProfile]);

    const handleToggleMenu = () => {
        console.log("SiteHeader :: handleToggleMenu :: menuIsOpen = ", menuIsOpen);

        if (menuIsOpen) {
            handleCloseMenu();
        } else {
            handleOpenMenu();
        }
    }

    const handleOpenMenu = () => {
        setTimeout(() => {
            document.addEventListener("click", handleCloseMenu);
        }, 100);
        setMenuIsOpen(true);
    }

    const handleMenuClick = () => {
        handleCloseMenu();
    }


     const handleCloseMenu = () => {
        document.removeEventListener("click", handleCloseMenu);
        setMenuIsOpen(false);
    }

    const handleToggleAdminMenu = () => {
        console.log("SiteHeader :: handleToggleAdminMenu :: adminMenuIsOpen = ", adminMenuIsOpen);

        if (adminMenuIsOpen) {
            handleCloseAdminMenu();
        } else {
            handleOpenAdminMenu();
        }
    }

    const handleOpenAdminMenu = () => {
        setTimeout(() => {
            document.addEventListener("click", handleCloseAdminMenu);
        }, 100);
        setAdminMenuIsOpen(true);
    }

    const handleAdminMenuClick = () => {
        handleCloseAdminMenu();
    }

     const handleCloseAdminMenu = () => {
        document.removeEventListener("click", handleCloseAdminMenu);
        setAdminMenuIsOpen(false);
    }

    return (
        <>
            <div className={styles.root}>
                <div className={styles.logo}>
                    <NavLink to="/" title="WCC Homepage" className={styles.LogoLink}>
                        <img src={logoImage} alt="Williamson County Citizens logo"/>
                    </NavLink>
                </div>

                <div className={styles.fullMenu}>
                    <div className={styles.links}>
                        <NavLink to={"/"}>Home</NavLink>
                        <NavLink to={"/about/"}>About</NavLink>
                        <NavLink to={"/grassroots-spotlight/"}>Grassroots<br/> Spotlight</NavLink>
                        <NavLink className={styles.secondaryItem} to={"/local-government/"}>Your Local<br/>Government</NavLink>
                        <NavLink className={styles.secondaryItem} to={"/elections/"}>Election<br/>Calendar</NavLink>
                        <NavLink className={styles.tertiaryItem} to={"/voting-locations/"}>Voting<br/>Locations</NavLink>
                        <a
                            href="https://ovr.govote.tn.gov/"
                            title="Go Vote TN.gov"
                            target="_blank"
                            className={styles.tertiaryItem}
                        >Register<br/>To Vote</a>
                    </div>
                </div>
                {isAdmin &&
                    <div className={styles.adminMenu}>
                        <HamburgerMenu
                            key={"admin_menu"}
                            onClick={handleToggleAdminMenu}
                            isOpen={adminMenuIsOpen}
                            iconClass={"fa-solid fa-user-gear"}
                            openIconClass={"fa-regular fa-user-gear"}
                            stacked
                            hideLabel
                        />
                        <div
                            className={classnames(styles.subMenu, {
                                [styles.isOpen]: adminMenuIsOpen,
                            })}
                            onClick={handleAdminMenuClick}
                        >
                            <NavLink to={"/grassroots-spotlight/create"}>Add New Article</NavLink>
                            <NavLink className={styles.secondaryItem} to={"/reports"}>Reports</NavLink>
                            <a
                                href="/admin/"
                                title="Django Admin"
                                target="_blank"
                            >Django Admin</a>
                            <a
                                href="/logout"
                                title="Log out"
                            >Log Out</a>
                        </div>
                    </div>
                }
                <div className={styles.mobileMenu}>
                    <HamburgerMenu
                        key={"mobile_menu"}
                        onClick={handleToggleMenu}
                        isOpen={menuIsOpen}
                        useBars
                    />
                    <div
                        className={classnames(styles.subMenu, {
                            [styles.isOpen]: menuIsOpen,
                        })}
                        onClick={handleMenuClick}
                    >
                        <NavLink className={styles.primaryItem} to={"/"}>Home</NavLink>
                        <NavLink className={styles.primaryItem} to={"/about/"}>About</NavLink>
                        <NavLink className={styles.primaryItem} to={"/grassroots-spotlight/"}>Grassroots Spotlight</NavLink>
                        <NavLink className={styles.secondaryItem} to={"/local-government/"}>Your Local Government</NavLink>
                        <NavLink className={styles.secondaryItem} to={"/elections/"}>Election Calendar</NavLink>
                        <NavLink className={styles.tertiaryItem} to={"/voting-locations/"}>Voting Locations</NavLink>
                        <a
                            href="https://ovr.govote.tn.gov/"
                            title="Go Vote TN.gov"
                            target="_blank"
                            className={styles.tertiaryItem}
                        >Register To Vote</a>
                    </div>
                </div>
            </div>
            <SiteNotification />
        </>
    );
}


SiteHeader.propTypes = {

}

SiteHeader.defaultProps = {

}
export default connect(
    (state) => ({
        userProfile: state.user.userProfile,
    }),
    null
)(SiteHeader);
