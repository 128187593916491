import React, {useEffect, useMemo, useState} from "react";
import { connect } from "react-redux";
import styles from "./SiteNotification.scss";
import classnames from "classnames";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import { BANNER, getPriorityClassName } from "@src/constants/SiteNotifications";
import * as SiteNotificationActions from "@src/actions/SiteNotificationActions";
import {filterNotificationsByType} from "@src/utils/siteNotificationUtils";

function SiteNotification({
    dismissSiteNotification,
    loadSiteNotifications,
    siteNotifications,

}) {
    const [bannerNotifications, setBannerNotifications] = useState({});
    const [activeNotification, setActiveNotification] = useState({});

    useEffect(() => {
        loadSiteNotifications();
    }, [])

    useEffect(() => {
        if (siteNotifications) {
            const filteredNotifications = filterNotificationsByType(siteNotifications, BANNER);
            console.log("SiteNotification :: effect :: filteredNotifications = ", filteredNotifications);
            setBannerNotifications(filteredNotifications);
            setActiveNotification(filteredNotifications[0] || null);
        }
    }, [siteNotifications]);

    const handleClick = () => {
        dismissSiteNotification(activeNotification.id);
        setActiveNotification(bannerNotifications[1] || null);
    }
    if (activeNotification && Object.keys(activeNotification).length) {
        const priorityClass = getPriorityClassName(activeNotification.priority);
        return (
            <div className={classnames(styles.bannerContainer, activeNotification.style, priorityClass, {
                [styles.sticky]: activeNotification.stickToTop,
            })}>
                {bannerNotifications.length > 1 && <span className={styles.oneOf}>1 of {bannerNotifications.length}</span>}
                <p className={styles.bannerBody} dangerouslySetInnerHTML={{__html: activeNotification.body}} />
                {!!activeNotification.dismissible &&
                    <button className={styles.bannerButton}
                            onClick={handleClick}><i className="fas fa-times"></i></button>}
            </div>
        )
    } else {
        return null;
    }
};

export default connect(
    (state) => ({
        siteNotifications: state.appState.siteNotifications,
    }),    (dispatch) => ({
        loadSiteNotifications: () => dispatch(SiteNotificationActions.loadActiveSiteNotifications()),
        dismissSiteNotification: (id) => dispatch(SiteNotificationActions.dismissSiteNotification(id)),
    })
)(SiteNotification);
