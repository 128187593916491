import React, {useEffect, useMemo, useRef, useState} from "react";
import map from "lodash/map";
import styles from "./Carousel.scss";
import ArticleTile from "@src/components/ArticleTile";
import Button from "@src/components/shared/elements/Button";
import * as ArticleActions from "@src/actions/ArticleActions";


function Carousel({ children }) {
    const [carouselIndex, setCarouselIndex] = useState(0);
    const [slidesWidth, setSlidesWidth]  = useState(0);
    const slidesRef = useRef();

    useEffect(() => {
        const updateSlidesWidth = () => {
            if (slidesRef.current) {
                const containerBounds = slidesRef.current.getBoundingClientRect();
                setSlidesWidth(containerBounds.width);
            }
        };

        updateSlidesWidth();

        const resizeObserver = new ResizeObserver(updateSlidesWidth);
        if (slidesRef.current) {
            resizeObserver.observe(slidesRef.current);
        }

        return () => {
            if (slidesRef.current) {
                resizeObserver.unobserve(slidesRef.current);
            }
        };
    }, []);

    const slidesCount = useMemo(() => {
        return children?.length || 0;
    }, [children]);

    const carouselSlidesLeft = useMemo(() => {
        if (slidesCount > 0 && slidesWidth > 0) {
            const singleSlide = slidesWidth / slidesCount;
            return `-${carouselIndex * singleSlide}px`;
        }
        return "0";
    }, [carouselIndex, slidesWidth, slidesCount]);

    const handlePrev = () => {
        if (carouselIndex !== 0) {
            setCarouselIndex(carouselIndex - 1);
        }
    }

    const handleNext = () => {
    if (carouselIndex < slidesCount - 1) {
        setCarouselIndex(carouselIndex + 1);
    }
};

    return (
        <div className={styles.root}>
            <div className={styles.prev}>
                <Button disabled={carouselIndex === 0} iconOnly buttonIcon={"fa-solid fa-caret-left"} onClick={handlePrev}/>
            </div>
            <div className={styles.listContainer}>
                <div className={styles.list} style={{ marginLeft: carouselSlidesLeft }} ref={slidesRef}>
                    {children}
                </div>
            </div>
            <div className={styles.next}>
                <Button disabled={carouselIndex === slidesCount-1} iconOnly buttonIcon={"fa-solid fa-caret-right "} onClick={handleNext} />
            </div>
        </div>
    );
}

Carousel.propTypes = {}

export default Carousel;
