import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./MapListContent.scss";
import Button from "@src/components/shared/elements/Button";
import MapList from "@src/components/maps/MapList";
import filter from "lodash/filter";
import * as MapActions from "@src/actions/MapActions";

function MapListContent({
    isHomepage,
    lookupMap,
    loadMaps,
    maps,
    onHandleHasDefault,
    showOnlyLookup, // New prop
}) {
    useEffect(() => {
        if (!maps) {
            loadMaps();
        }
    }, []);

    const handleHasDefault = (mapSlug) => {
        if (onHandleHasDefault) {
            onHandleHasDefault(mapSlug);
        }
    };

    const getHeadingTag = (headingText) => {
        if (isHomepage) {
            return null;
        }
        return <h2>{headingText}</h2>;
    };

    // If `showOnlyLookup` is true, render only the lookup button
    if (showOnlyLookup && lookupMap) {
        return (
            <div className={styles.lookupToolHome}>
                <div>Get specific results using your address</div>
                <Link to={`/local-government/${lookupMap.slug}/`}>
                    <Button buttonText={"Lookup My Address"} />
                </Link>
            </div>
        );
    }

    // Otherwise, render the full content
    return (
        <>
            <div className={styles.localMaps}>
                {getHeadingTag("Find Your Local Representatives by Selecting a Map Below")}
                <h4>County</h4>
                <div className={styles.list}>
                    <MapList onHasDefault={onHandleHasDefault} level={"county"} />
                </div>
                <h4>State</h4>
                <div className={styles.list}>
                    <MapList onHasDefault={onHandleHasDefault} level={"state"} />
                </div>
                <h4>Federal</h4>
                <div className={styles.list}>
                    <MapList onHasDefault={onHandleHasDefault} level={"national"} />
                </div>
                <h4>City</h4>
                <div className={styles.list}>
                    <MapList onHasDefault={onHandleHasDefault} level={"city"} />
                </div>
            </div>
            {lookupMap && !isHomepage && (
                <div className={styles.lookupTool}>
                    {getHeadingTag("Address Lookup Tool")}
                    <div>Get specific results using your address</div>
                    <Link to={`/local-government/${lookupMap.slug}/`}>
                        <Button buttonText={"Lookup My Address"} />
                    </Link>
                </div>
            )}
        </>
    );
}

MapListContent.propTypes = {
    onHandleHasDefault: PropTypes.func,
    isHomepage: PropTypes.bool,
    showOnlyLookup: PropTypes.bool, // New prop type
};

export default connect(
    (state) => {
        const maps = state.mapState.maps;
        const lookupMaps = filter(maps, mapObj => mapObj.isLookup);
        const lookupMap = lookupMaps.length ? lookupMaps[0] : null;
        return {
            maps,
            lookupMap,
        }
    },
    (dispatch) => ({
        loadMaps: () => dispatch(MapActions.loadMaps()),
    })
)(MapListContent);
