import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import map from "lodash/map";
import styles from "./MapContainer.scss";
import * as MapLayerTypes from "@src/constants/MapLayerTypes";
import AddressLookup from "@src/components/maps/AddressLookup";
import MapComponent from "@src/components/maps/MapComponent";
import Button from "@src/components/shared/elements/Button";
import * as MapActions from "@src/actions/MapActions";
import {getWindowSize, MOBILE, PORTRAIT} from "@src/constants/WindowConstants";
import {useLocation} from "react-router-dom";

function MapContainer({
    mapConfig,
    selectedLocation,
    onMapLoaded,
    setUserLocations,
    setMapLocation,
    showBorder,
    clearMapLocation,
    children,
    createLocation
}) {

    useEffect(() => {
        if (selectedLocation) {
            console.log("MapContainer :: create :: selectedLocation = ", selectedLocation);
            const {address_components: address} = selectedLocation;
            const addressLine = `${address[0].long_name} ${address[1].long_name}`
            createLocation({
                ...selectedLocation,
                lat: selectedLocation.geometry.location.lat(),
                lng: selectedLocation.geometry.location.lng(),
                address: addressLine,
                city: address[2].long_name,
                county: address[3].long_name,
                stateProvince: address[4].short_name,
                country: address[5].long_name,
                postalCode: address[6].long_name,
                googlePlaceId: selectedLocation.place_id,
            });
        }
    }, [selectedLocation]);

    const screenSizes = useMemo(() => {
        const { size, orientation} = getWindowSize();
        return {
            size,
            orientation,
        };
    }, []);

    const handlePlaceSelected = (placesInfo) => {
        // console.log("MapContainer :: handlePlaceSelected :: placesInfo = ", placesInfo);
        setMapLocation(placesInfo);
    }

    const handleMatchedLocations = (locations) => {
        setUserLocations(locations)
    }

    const renderSearchBox = () => {
        if (mapConfig.allowSearch) {
            if (selectedLocation) {
                return (
                    <>
                        <div className={styles.selectedLocation}>
                            <div className={styles.address}>{selectedLocation.formatted_address}</div>
                            <Button
                                className={styles.clearBtn}
                                buttonColor={"white"}
                                xsmall
                                onClick={clearMapLocation}
                                buttonText={"clear"}
                            />
                        </div>

                    </>
                );
            }
            return <AddressLookup loadScript onPlaceSelected={handlePlaceSelected} mapPosition={mapConfig.mapPosition} />;
        }

        return null;
    }

    return (
        <div className={styles.root}>
            {renderSearchBox()}
            <MapComponent
                mapConfig={mapConfig}
                onMapLoaded={onMapLoaded}
                onMatchedLocations={handleMatchedLocations}
                showBorder={showBorder}
                screenSizes={screenSizes}
            >{children}</MapComponent>
        </div>
    );
}


MapContainer.propTypes = {
    mapConfig: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
        maxZoom: PropTypes.number,
        minZoom: PropTypes.number,
        zoom: PropTypes.number,
        centerLatitude: PropTypes.string,
        centerLongitude: PropTypes.string,
        isDefault: PropTypes.bool,
        allowSearch: PropTypes.bool,
        slug: PropTypes.string,
        googleMapId: PropTypes.string,
        mapStyles: PropTypes.string,
        created: PropTypes.string,
        organization: PropTypes.string,
        layers: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            agency: PropTypes.shape({
                id: PropTypes.string,
                name: PropTypes.string,
                level: PropTypes.string,
                website: PropTypes.string,
            }),
            name: PropTypes.string,
            active: PropTypes.bool,
            geojsonUrl: PropTypes.string,
            geojsonFile: PropTypes.string,
            featureId: PropTypes.string,
            created: PropTypes.string,
            updated: PropTypes.string,
        })),
        mapPosition: PropTypes.object,
    }).isRequired,
    onMapLoaded: PropTypes.func,
    showBorder: PropTypes.bool,
}

export default connect((state, ownProps) => ({
    userLocations: state.user.locations,
    selectedLocation: state.mapState.location,
}), (dispatch) => ({
    setUserLocations: (locations) => dispatch(MapActions.setUserLocations(locations)),
    setMapLocation: (location) => dispatch(MapActions.setMapLocation(location)),
    clearMapLocation: () => dispatch(MapActions.clearMapLocation()),
    createLocation: (placesInfo) => dispatch(MapActions.createLocation(placesInfo)),
}))(MapContainer);
