import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment";
import styles from "./ArticlePage.scss";
import Button from "@src/components/shared/elements/Button";
import * as ArticleActions from "@src/actions/ArticleActions";
import {formatArticle} from "@src/utils/ArticleUtils";
import {setPageTitle} from "@src/utils/windowHelpers";

function ArticlePage({ article, articlePath, loadArticle, isAdmin }) {

    const [isLoaded, setIsLoaded] = useState(false);
    const articleBody = useMemo(() => article?.content || "NO TEXT", [article]);
    useEffect(() => {
        if (!isLoaded) {
            loadArticle(articlePath);
            setIsLoaded(true);
        }
    },[isLoaded]);

    useEffect(() => {
        if (article) {
            setPageTitle(`${article.headline} :: Article`, window.location);
        }
    }, [article]);

    if (!isLoaded || !article) {
        return <div><h1>LOADING...</h1></div>
    }

    const publishedDate = article.published ? moment(article.publishedDate).format("MMMM DD, YYYY") : <span style={{ color: "#CC0000", fontWeight: 600 }}>NOT PUBLISHED</span>;

    // const authorName = article.author?.fullName || ""; // Keeping things anonymous for now
    return (
        <div className={styles.root}>

            <article>
                <header>
                    <div className={styles.headlineRow}>
                        <div className={styles.controls}>
                            <Link className={styles.backLink} to={"/grassroots-spotlight"}><i className={"fa-solid fa-chevron-left"} /> All Spotlights</Link>
                            {!!isAdmin && <Link className={classnames("btn-full btn-light small", styles.editLink)} to={`/grassroots-spotlight/${article.path}/edit`}>
                                <Button buttonText={"Edit"} buttonColor={"light"} buttonIcon={"fa-solid fa-pencil"} small />
                            </Link>}
                        </div>
                        <h1>{article.headline}</h1>

                    </div>
                    <div className={styles.dateline}>
                        <span className={styles.date}>{publishedDate}</span>
                    </div>
                </header>
                <div className={styles.articleBody} dangerouslySetInnerHTML={{__html: articleBody}} />
                {/*<div>SOCIAL LINKS HERE</div>*/}
            </article>
        </div>
    );
}


ArticlePage.propTypes = {
    articlePath: PropTypes.string.isRequired,
}

ArticlePage.defaultProps = {}

export default connect(
    (state, ownProps) => {
        const articlePath = ownProps.articlePath;
        const article = state.entities.articles[articlePath] || null;
        const articleId = article?.id;
        const userProfile = state.user.userProfile;

        return {
            articleId,
            articlePath,
            article,
            isAdmin: userProfile.isAdmin,
        }
    },
    (dispatch) => ({
        loadArticle: (articlePath) => dispatch(ArticleActions.loadArticleBySlug(articlePath)),
    })
)(ArticlePage);
